import { render, staticRenderFns } from "./ActionPremiumStart.vue?vue&type=template&id=4aa40a27&scoped=true"
import script from "./ActionPremiumStart.vue?vue&type=script&lang=js"
export * from "./ActionPremiumStart.vue?vue&type=script&lang=js"
import style0 from "./ActionPremiumStart.vue?vue&type=style&index=0&id=4aa40a27&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4aa40a27",
  null
  
)

export default component.exports